import timeImg from '../../Icons/png/time.png';
import calendarImg from '../../Icons/png/calendar.png';
import React, { useState } from "react";
import "flatpickr/dist/themes/material_green.css";
import { apiClient } from '../../services/api';
import { AddPointage } from './AddPointage';
import SimpleNotification from '../../components/Notifications/SimpleNotification';

export const PointagesIndex = () => {
    
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [pointages, setPointages] = useState([]);

    function deletePointage(id) {
      let confirmed = window.confirm('Êtes-vous sûr de vouloir supprimer cette élément ?')
      if(confirmed) {
        
        apiClient.delete('/api/pointages/' + id)
        .then(res => {
          setNotificationMessage(res.data.message);
          setShowNotification(true);        
          getPointages();
        })
        .catch(err => console.log(err));
      }
    }


    function getPointages() {
      apiClient.get('/api/pointages')

      .then(response => {

        setPointages(response.data.data)

      })

      .catch(error => console.error(error));
    }

    React.useEffect(() => { getPointages() }, []);
    
    return(
        <div className="max-w-5xl mx-auto lg:min-w-0 lg:flex-1">
        <AddPointage getPointages={getPointages}></AddPointage>
        <ul className="bg-white relative z-0 divide-y divide-gray-200 border-b border-gray-200">
            {pointages.map((pointage, key) => { return(   
                <li key={key}>
                  <div className="flex items-center px-4 py-4 sm:px-6 block hover:bg-gray-50">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex-shrink-0">
                        {/* <img src={avatar} className="w-10 h-10" alt="Avatar Icon" /> */}
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="mt-2 text-md font-medium truncate flex items-center">
                            <img src={timeImg} className="w-6 h-6 mr-3 mt-1" alt="Time icon" />
                              {pointage.heure}
                         </p>
                        </div>
                        <div className="hidden md:flex">
                          <div className="flex items-center">
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                <img src={calendarImg} className="w-6 h-6 mr-3 mt-1" alt="Time icon" />
                                {pointage.date}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    <div className="flex items-center">
                      <svg onClick={() => { deletePointage(pointage.id); }} className="cursor-pointer w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                    </div>
                    </div>
                  </div>
              </li>
            )})}
        </ul>
        <SimpleNotification setShowNotification={setShowNotification} showNotification={showNotification} notificationMessage={notificationMessage} />
    </div>
        );
    }