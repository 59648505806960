import user from '../../Icons/png/user.png';
import admin from '../../Icons/png/admin.png'; 
import avatar from '../../Icons/png/avatar.png';
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { apiClient } from '../../services/api';
import { useHistory } from 'react-router-dom';
import SimpleNotification from '../../components/Notifications/SimpleNotification';


export const EmployeesIndex = (props) => {


    const inputNom = useRef(null);
    const [employees, setEmployees] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [registrationErrors, setRegistrationErrors] = useState([]);
    const history = useHistory();
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState(null);

    function editEmployee(employee) {
      history.push('/employees/edit', employee);
    }

    function showEmployee(employee) {
        history.push('/employees/show', employee);
      }

    function deleteEmployee(employee){
        
        let confirmed = window.confirm('Êtes-vous sûr de vouloir supprimer cette élément ?')
        
        if(confirmed) {
            apiClient.delete('/api/employees/' + employee.id, employee)
            .then(response => {
                    setNotificationMessage(response.data.message);
                    setShowNotification(true);        
                    getEmployees();
            })
            .catch(error => console.log(error));
        }
    }

    function getEmployees() {
        apiClient.get('/api/employees')

        .then(response => {

            setEmployees(response.data.data)
        })

        .catch(error => console.error(error));
    }

    React.useEffect(() => {
        
        getEmployees();

    }, []);
   
    const formik = useFormik({
        initialValues: {
            lastname: '',
            firstname: '',
            email: '',
            role_id: '',
            password: '',
            password_confirmation: ''
        },
        validationSchema: Yup.object({
          firstname: Yup.string().required('Veullez saisir un prénom'),
          lastname: Yup.string().required('Veullez saisir un nom'),
          email: Yup.string().email().required('Veuillez saisir une adresse e-mail'),
          role_id: Yup.number().required('Veuillez choisir un rôle'),
          password: Yup.string().required('Veullez saisir un mot de passe').min(8, 'Votre mot de passe doit avoir au moins 8 caracteres').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
          password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: values => {

            let user = {
                lastname: values.lastname,
                firstname: values.firstname,
                email: values.email,
                role_id: values.role_id, 
                password: values.password,
                password_confirmation: values.password_confirmation
            };

            apiClient.post('/api/employees', {user}).then(response => {

                setEmployees(response.data.data);
                setShowForm(false);
                setRegistrationErrors([]);
                resetForm();

                setNotificationMessage(response.data.message);
                setShowNotification(true);        

            }).catch(error => {
                setRegistrationErrors([error.response.data.errors.password[0]]);
            })
        }
    });

    function addEmployee() {
        formik.resetForm();
        setShowForm(true);
        setTimeout(() => {
            inputNom.current.focus();
        }, 100);
    }

    function resetForm() {
        formik.resetForm();
        setShowForm(false);
      }
    return(
        <div className="max-w-5xl mx-auto lg:min-w-0 lg:flex-1">
                <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                    <div className="flex items-center">
                        <h1 className="text-xl font-bold flex-1 text-lg font-medium">Collaborateurs</h1>
                        <button onClick={addEmployee} className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                            <svg className="w-6 h-6 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                            </svg>
                            Ajouter collaborateur
                        </button>
                    </div>
                </div>
                <ul className="bg-white relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                <li className={showForm ? '' : 'hidden'}>
                    <form onSubmit={formik.handleSubmit}>
                    <div className={"rounded-md bg-red-50 p-4 "+ (registrationErrors.length === 0 ? 'invisible' : ' visible')}>
                        <div className="flex">
                            <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                            </div>
                            <div className="ml-3 ">
                            <h3 className="text-sm font-medium text-red-800">
                                There was an error with your submission
                            </h3>
                            <div className="mt-2 text-sm text-red-700">
                                <ul className="list-disc pl-5 space-y-1">
                                    {registrationErrors.map((message, key)=>{ 
                                        return(
                                        <li key={key}>
                                            {message}
                                        </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>

                        <div className="flex items-center px-4 py-4 sm:px-6 block hover:bg-gray-50">
                            <div className="min-w-0 flex-1 flex">
                                <div className="flex-shrink-0">
                                    {/* <img src={avatar} className="w-10 h-10" alt="Avatar Icon" /> */}
                                </div>
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
                                    <div>
                                        <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">Nom</label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input  ref={inputNom} 
                                                    onBlur={formik.handleBlur} 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.lastname} 
                                                    type="text" 
                                                    name="lastname" 
                                                    id="lastname" 
                                                    className={"block w-full pr-10 focus:outline-none sm:text-sm rounded-md" + (formik.touched.lastname && formik.errors.lastname ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')} 
                                                    placeholder="Nom" 
                                                    aria-invalid="true" 
                                                    aria-describedby="nom-error" 
                                                />
                                            <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.lastname && formik.errors.lastname ? ' visible' : ' invisible')}>
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p className="h-8 pl-1 pt-1 text-sm text-red-600"> {formik.touched.lastname && formik.errors.lastname ? <span>{formik.errors.lastname}</span> : ' '}</p>
                                    </div>
                                    <div>
                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">Prénom</label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input 
                                                    onBlur={formik.handleBlur} 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.firstname} 
                                                    type="text" 
                                                    name="firstname" 
                                                    id="firstname" 
                                                    className={"block w-full pr-10 focus:outline-none sm:text-sm rounded-md" + (formik.touched.firstname && formik.errors.firstname ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')} 
                                                    placeholder="Prénom" 
                                                    aria-invalid="true" 
                                                    aria-describedby="firstname-error" 
                                                />
                                            <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.firstname && formik.errors.firstname ? ' visible' : ' invisible')}>
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p className="h-8 pl-1 pt-1 text-sm text-red-600"> {formik.touched.firstname && formik.errors.firstname ? <span>{formik.errors.firstname}</span> : ' '}</p>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="pl-1 block text-sm font-medium text-gray-700">E-mail</label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input 
                                                onBlur={formik.handleBlur} 
                                                onChange={formik.handleChange} 
                                                value={formik.values.email} 
                                                type="text" 
                                                name="email" 
                                                id="email" 
                                                className={"block w-full pr-10 focus:outline-none sm:text-sm rounded-md" + (formik.touched.email && formik.errors.email ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')} 
                                                placeholder="E-mail" 
                                                aria-invalid="true" 
                                                aria-describedby="email-error" 
                                            />
                                            <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.email && formik.errors.email ? ' visible' : ' invisible')}>
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p className="h-8 pl-1 pt-1 text-sm text-red-600"> {formik.touched.email && formik.errors.email ? <span>{formik.errors.email}</span> : ' '}</p>
                                    </div>
                                    <div>
                                        <label htmlFor="role_id" className="pl-1 block text-sm font-medium text-gray-700">Rôle</label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <select 
                                                onBlur={formik.handleBlur} 
                                                onChange={formik.handleChange} 
                                                value={formik.values.role_id} 
                                                name="role_id" 
                                                id="role_id" 
                                                className={"cursor-pointer block w-full pr-10 focus:outline-none sm:text-sm rounded-md" + (formik.touched.role_id && formik.errors.role_id ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')} 
                                                aria-invalid="true" 
                                                aria-describedby="role_id-error" 
                                            >
                                              <option value="">-- Veuillez choisir un rôle --</option>
                                              <option value="2">Administrateur</option>
                                              <option value="3">Utilisateur</option>
                                            </select>
                                            <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.role_id && formik.errors.role_id ? ' visible' : ' invisible')}>
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p className="h-8 pl-1 pt-1 text-sm text-red-600"> {formik.touched.role_id && formik.errors.role_id ? <span>{formik.errors.role_id}</span> : ' '}</p>
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="pl-1 block text-sm font-medium text-gray-700">Mot de passe</label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input 
                                                onBlur={formik.handleBlur} 
                                                onChange={formik.handleChange} 
                                                value={formik.values.password} 
                                                type="password" 
                                                name="password" 
                                                id="password" 
                                                className={"block w-full pr-10 focus:outline-none sm:text-sm rounded-md" + (formik.touched.password && formik.errors.password ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')} 
                                                placeholder="Mot de passe" 
                                                aria-invalid="true" 
                                                aria-describedby="password-error" 
                                            />
                                            <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.password && formik.errors.password ? ' visible' : ' invisible')}>
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p className="h-8 pl-1 pt-1 text-sm text-red-600"> {formik.touched.password && formik.errors.password ? <span>{formik.errors.password}</span> : ' '}</p>
                                    </div>
                                    <div>
                                        <label htmlFor="confirmpassword" className="pl-1 block text-sm font-medium text-gray-700">Confirmation mot de passe</label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input 
                                                onBlur={formik.handleBlur} 
                                                onChange={formik.handleChange} 
                                                value={formik.values.password_confirmation} 
                                                type="password" 
                                                name="password_confirmation" 
                                                id="password_confirmation" 
                                                className={"block w-full pr-10 focus:outline-none sm:text-sm rounded-md" + (formik.touched.password_confirmation && formik.errors.password_confirmation ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')} 
                                                placeholder="Confirmation de mot de passe" 
                                                aria-invalid="true" 
                                                aria-describedby="confirmpassword-error" 
                                            />
                                            <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.password_confirmation && formik.errors.password_confirmation ? ' visible' : ' invisible')}>
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p className="h-8 pl-1 pt-1 text-sm text-red-600"> {formik.touched.password_confirmation && formik.errors.password_confirmation ? <span>{formik.errors.password_confirmation}</span> : ' '}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center justify-center">
                                    <button type="submit">
                                        <svg className="cursor-pointer w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                    </button>
                                    <svg onClick={resetForm} className="cursor-pointer w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </form>
                </li>
            </ul>
                <ul className="bg-white relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                    {employees.map((employee, key) => { return(   
                        <li key={key}>
                          <div className="flex items-center px-4 py-4 sm:px-6 block hover:bg-gray-50">
                            <div className="min-w-0 flex-1 flex items-center">
                              <div className="flex-shrink-0">
                                <img src={avatar} className="w-10 h-10" alt="Avatar Icon" />
                              </div>
                              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                  <p className="text-md font-medium truncate">{employee.fullname}</p>
                                  <p className="hidden mt-1 md:flex items-center text-sm text-gray-500">
                                    <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                    </svg>
                                    <span className="truncate">{employee.email}</span>
                                  </p>
                                </div>
                                <div className="hidden md:flex">
                                  <div className="flex items-center">
                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                        {employee.role_id === 3 ? <img className="w-6 h-6 mr-3"  src={user} alt="User Icon"/> : <img className="w-6 h-6 mr-3" src={admin} alt="Admin Icon"/>}
                                        {employee.role_id === 3 ? 'Utilisateur': 'Administrateur'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                            <div className="flex items-center">
                              <svg onClick={() => { showEmployee(employee) }} className="cursor-pointer w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                              </svg>
                              <svg onClick={() => { editEmployee(employee) }} className="cursor-pointer w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                              </svg>
                              <svg onClick={() => { deleteEmployee(employee) }} className="cursor-pointer w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                              </svg>
                            </div>
                            </div>
                          </div>
                      </li>
                    )})}
                </ul>
                <SimpleNotification setShowNotification={setShowNotification} showNotification={showNotification} notificationMessage={notificationMessage} />
            </div>
    );
}