import { useEffect, useState } from "react";
import { CompanyIcon } from "../Icons/CompanyIcon"
import moment from 'moment';

export const LoggedInUser = (props) => {

    const [greetUser, setGreetUser] = useState(null);

    useEffect(() => {

        var currentHour = moment().format("HH");

        if (currentHour >= 3 && currentHour < 16){
            return setGreetUser("Bonjour");
        } else if (currentHour >= 16){
            return setGreetUser("Bonsoir");
        }  else {
            return setGreetUser("Bonjour");
        }
      }, []);


    return (
        <div className="flex items-center">
            <div>
                <div className="flex items-center">
                    {/* <img className="h-16 w-16 rounded-full sm:hidden" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2.6&amp;w=256&amp;h=256&amp;q=80" alt="" /> */}
                    <h1 className="ml-3 mt-3 sm:mt-0 text-2xl font-bold  leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {greetUser}, <br className="sm:hidden" />{props.fullName}
                    </h1>
                </div>
                {props.role === '1' ? 
                ''
                :
                <dl className="ml-3 mt-3 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Company</dt>
                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                        <CompanyIcon />   
                        {props.company}
                    </dd>
                </dl>
                }
            </div>
        </div>
    );
}