import { ErrorMessage, useField } from "formik";

export const TextInput = ({ name, label, ...props }) => {
    const [field, meta] = useField(name);
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                {...field} {...props}
                className={"block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2 border-gray-300 border-2 rounded-md"  + (meta.touched && meta.errors ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')}
                />
                <p className="h-4 pl-1 pt-1 text-sm text-red-600"> 
                    <ErrorMessage name={field.name} component="span" />
                </p>
            </div>
        </div>
    );
  };