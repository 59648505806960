import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { apiClient } from "../../services/api";

export const TenantsIndex = () => {

    const [tenants, setTenants] = useState([]);
    const history = useHistory();

    React.useEffect(() => { getTenants(); }, []);

    function getTenants() {
        apiClient.get('/api/tenants')

            .then(response => {
                setTenants(response.data.tenants)
            })

            .catch(error => console.error(error));
    }

    function addTenants() {
        history.push('/tenants/add');
    }

    function deleteTenants(tenant) {
        apiClient.delete('/api/tenants/' + tenant.id)
            .then(response => {
                getTenants();
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="max-w-5xl mx-auto lg:min-w-0 lg:flex-1">
            <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                <div className="flex items-center">
                    <h1 className="text-xl font-bold flex-1 text-lg font-medium">Liste de tenants</h1>
                    <button type="button" onClick={addTenants} className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                        <svg className="w-6 h-6 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                        </svg>
                        Ajouter Tenant
                    </button>
                </div>
            </div>
            <ul className="bg-white relative z-0 divide-y divide-gray-200 border-b border-gray-200 mt-5">
                {tenants.map((tenant, key) => {
                    return (
                        <li key={key}>
                            <div className="flex items-center px-4 py-4 sm:px-6 block hover:bg-gray-50">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 grid grid-cols-2 md:gap-4">
                                        <div className="text-md flex flex-col justify-center font-medium truncate">
                                            <span>{tenant.enterprise}</span>
                                            <div className="text-sm text-gray-500">
                                                Tenand ID: {tenant.id}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center text-gray-500">
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-cyan-100 text-cyan-800">
                                                <svg className="-ml-0.5 mr-1.5 h-5 w-5 text-cyan-400" fill="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
                                                </svg>
                                                {tenant.employees_count}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="hidden md:flex items-center">
                                        <svg onClick={() => { deleteTenants(tenant) }} className="cursor-pointer w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}