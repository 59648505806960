import React, { useState } from "react";
import badgeImg from '../../Icons/png/badge.png';
import { apiClient } from "../../services/api";
import { AddBadge } from "./AddBadge";

export const BadgesIndex = () => {

    const [badges, setBadges] = useState([]);

    React.useEffect(() => { getBadges(); }, []);

    function getBadges() {
        apiClient.get('/api/badges')

            .then(response => {
                setBadges(response.data.badges)
            })

            .catch(error => console.error(error));
    }

    function deleteBadge(badge) {
        apiClient.delete('/api/badges/' + badge.badge_id)
            .then(response => {
                getBadges();
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="max-w-5xl mx-auto lg:min-w-0 lg:flex-1">

            <AddBadge getBadges={getBadges}></AddBadge>

            <ul className="bg-white relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                {badges.map((badge, key) => {
                    return (
                        <li key={key}>
                            <div className="flex items-center px-4 py-4 sm:px-6 block hover:bg-gray-50">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 grid grid-cols-2 md:gap-4">
                                        <div className="text-md flex items-center font-medium truncate">
                                            {badge.user}
                                        </div>
                                        <div className="flex">
                                            <div className="flex items-center">
                                                <p className="mt-2 flex items-center justify-center text-gray-500">
                                                    <img className="w-6 h-6 mr-2" src={badgeImg} alt="Badge Icon" />
                                                    <span className="text-lg mt-1">{badge.badge_id}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="hidden md:flex items-center">
                                        <svg onClick={() => { deleteBadge(badge) }} className="cursor-pointer w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}