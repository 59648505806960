import { useEffect, useRef, useState } from "react";
import { apiClient } from "../../services/api";

export const TransactionsIndex = () => {
    const [files, setFiles] = useState([]);
    function getHistory() {
        apiClient.get('/api/historytransactions')
        .then(response => setFiles(response.data.files))
        .catch(error => console.log(error));
    }

    const [fileDownloadUrl, setFileDownloadUrl] = useState(null);
    const downloadinput = useRef(null);

    function downloadFile(path) {

        apiClient.get('/api/downloadhistorytransactions' , {params: { path: path}})
        .then(response => {
            let output = response.data.file;
            
            const blob = new Blob([output]);
            setFileDownloadUrl(URL.createObjectURL(blob));
            
            downloadinput.current.click();
            URL.revokeObjectURL(fileDownloadUrl);
        })
        .catch(error => console.log(error))
    }

    useEffect(() => {
        getHistory();
    }, [])
    
    return (
        <div className="flex flex-col max-w-3xl mx-auto mt-5">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    {files.length === 0 ? 
                                <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center">
                                    <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                                    </svg>
                                    <span className="mt-2 block text-sm font-medium text-gray-900">
                                        Aucun fichier de transactions disponible.
                                    </span>
                                </div> 
                        :
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Heure
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {files.map((file, fileIdx) => (
                                    <tr key={fileIdx} className={fileIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{file.date}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{file.heure}</td>
                                        
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button onClick={() => { downloadFile(file.download) }} type="button" className="text-cyan-600 hover:text-cyan-900">
                                            <span>
                                                Download
                                            </span>
                                        </button>
                                        <a className="hidden"
                                            download="TRANSACTIONS.TXT"
                                            href={fileDownloadUrl}
                                            ref={downloadinput}
                                        >Download transactions file</a>
                                        </td>
                                    </tr>
                                ))}        
                            </tbody>
                        </table>
                    }
                    </div>
                </div>
            </div>
        </div>
    );
}