import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ProtectedRoute from './components/ProtectedRoute';
// import { ProjectsIndex } from './pages/Projects/Index';
import { Dashboard } from './pages/Dashboard/Index';
import { EmployeesIndex } from './pages/Employees/Index';
import { EmployeesEdit } from './pages/Employees/Edit';
import { BadgesIndex } from './pages/Badges/Index';
import { PointagesIndex } from './pages/Pointages/Index';
import { Timbreuse } from './pages/Timbreuse/Timbreuse';
import { Login } from './pages/Login/Login';
import { AppLayout } from './Layouts/AppLayout/AppLayout';
import { TenantsIndex } from './pages/Tenants/TenantsIndex';
import { TenantsCreate } from './pages/Tenants/TenantsCreate';
import { TransactionsIndex } from './pages/Transactions/TransactionsIndex';
import { EmployeesShow } from './pages/Employees/Show';

function App() {

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem('loggedIn') === 'true' || false
  );

  const [user, setUser] = useState(
    localStorage.getItem('user') || false);
 
  const [company, setCompany] = useState(
    localStorage.getItem('company') || false);

    const [role, setRole] = useState(
      localStorage.getItem('role') || false);

  const login = (user, company, role) => {
    setLoggedIn(true);
    setUser(user);
    setCompany(company);
    setRole(role);

    localStorage.setItem('loggedIn', true);
    localStorage.setItem('user', user);
    localStorage.setItem('company', company);
    localStorage.setItem('role', role);
  };

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login login={login} />
        </Route>

      <ProtectedRoute
            exact
            path="/timbreuse"
            loggedIn={loggedIn}
            component={() => <Timbreuse loggedInStateChanger={setLoggedIn} />}
        />

      <AppLayout role={role} loggedInStateChanger={setLoggedIn} user={user} company={company}>

          <ProtectedRoute
            exact
            path="/"
            loggedIn={loggedIn}
            component={Dashboard}
            />

          <ProtectedRoute
            exact
            path="/tenants"
            loggedIn={loggedIn}
            component={TenantsIndex}
          />
          
          <ProtectedRoute
            exact
            path="/tenants/add"
            loggedIn={loggedIn}
            component={TenantsCreate}
          />

          <ProtectedRoute
            exact
            path="/transactionfiles"
            loggedIn={loggedIn}
            component={TransactionsIndex}
          />


          <ProtectedRoute
            exact
            path="/employees"
            loggedIn={loggedIn}
            component={EmployeesIndex}
            />

         
          <ProtectedRoute
            exact
            path="/employees/edit"
            loggedIn={loggedIn}
            component={EmployeesEdit}
            />

          <ProtectedRoute
            exact
            path="/employees/show"
            loggedIn={loggedIn}
            component={EmployeesShow}
            /> 
          
          <ProtectedRoute
            exact
            path="/badges"
            loggedIn={loggedIn}
            component={BadgesIndex}
            />

          <ProtectedRoute
            exact
            path="/pointages"
            loggedIn={loggedIn}
            component={PointagesIndex}
            />

          {/* <ProtectedRoute
            exact
            path="/projets"
            loggedIn={loggedIn}
            component={ProjectsIndex}
            /> */}
        </AppLayout>

      </Switch>
    </Router>
  );
}

export default App;