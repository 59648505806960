import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  FolderIcon,
  HomeIcon,
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/react/outline'
import { Logo } from '../../Icons/Logo';
import { LienNav } from '../Navigation/NavLink';
import { CreditCardIcon, DownloadIcon, LogoutIcon, PlusIcon, UserGroupIcon, UserIcon, ViewListIcon } from '@heroicons/react/solid';
import { apiClient } from '../../services/api';


export const AppLayout = (props) => {
  
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [fileDownloadUrl, setFileDownloadUrl] = useState(null);
  const downloadinput = useRef(null);

  function downloadFile() {
    apiClient.get('/api/downloadtransactions')
    .then(response => {
      let output = response.data;
      
      const blob = new Blob([output]);
      setFileDownloadUrl(URL.createObjectURL(blob));
     
      downloadinput.current.click();
      URL.revokeObjectURL(fileDownloadUrl);
    })
    .catch(error => console.log(error))
  }

  const logout = () => {
    apiClient.post('/logout').then(response => {

      if (response.status === 204) {
        props.loggedInStateChanger(false);
        localStorage.setItem('loggedIn', false);
        localStorage.removeItem('user');
        localStorage.removeItem('company');
        localStorage.removeItem('role');
      }
    })
  };
  
  return(

    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-cyan-700">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex items-center flex-shrink-0 px-4">
                <Logo className="h-8 mr-4" color="#fff" />
                <span className="text-3xl font-bold leading-tight text-center text-white">MP Time</span>
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                <div className="px-2 space-y-1">
                  <LienNav path='/' icon={<HomeIcon className="mr-4 flex-shrink-0 h-6 w-6" />} name="Accueil" />

                  {props.role === '1' ?
                    <LienNav path='/tenants' isActive={true} icon={<UserGroupIcon  className="mr-4 flex-shrink-0 h-6 w-6" />} name="Tenants" />
                    :
                    ''
                  }

                  {props.role === '2' ? 
                    <div>

                      {/* <LienNav path='/projets' isActive={true} icon={<ClockIcon />} name="Projets" /> */}

                      <LienNav path='/employees' icon={<UserIcon  className="mr-4 flex-shrink-0 h-6 w-6" />} name="Collaborateurs" />

                      <LienNav path='/badges' icon={<CreditCardIcon  className="mr-4 flex-shrink-0 h-6 w-6" />} name="Badges" />

                      <LienNav path='/pointages' icon={<ViewListIcon  className="mr-4 flex-shrink-0 h-6 w-6" />} name="Mes pointages" />

                      <LienNav path='/transactionfiles' icon={<FolderIcon  className="mr-4 flex-shrink-0 h-6 w-6" />} name="Fichiers pointages" />

                      <LienNav path='/timbreuse' icon={<PlusIcon className="mr-4 flex-shrink-0 h-6 w-6" />} name="Timbreuse" />

                    </div>
                  :
                  ""
                  }

                {props.role === '3' ? 
                <div>

                {/* <LienNav path='/projets' isActive={true} icon={<ClockIcon />} name="Projets" /> */}

                <LienNav path='/pointages' icon={<ViewListIcon className="mr-4 flex-shrink-0 h-6 w-6" />} name="Mes pointages" />

                <LienNav path='/timbreuse' icon={<PlusIcon className="mr-4 flex-shrink-0 h-6 w-6" />} name="Timbreuse" />

              </div>
              : 
              ''
              } 

              <button
                className={"w-full text-cyan-100 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}
                onClick={logout}>
                <LogoutIcon className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" />
                <span>Se deconnecter</span>
              </button>
              </div>
              <div className="mt-6 pt-6">
              <div className="px-2 space-y-1">
                {props.role === '2' ?
                <div>
                  <button onClick={downloadFile} type="button" className="w-full text-cyan-100 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md">
                    <DownloadIcon className="mr-4 flex-shrink-0 h-6 w-6" />
                    <span>
                      Produire fichier Transactions
                    </span>
                  </button>
                  <a className="hidden"
                    download="TRANSACTIONS.TXT"
                    href={fileDownloadUrl}
                    ref={downloadinput}
                  >download it</a>
                </div>
                  :
                  ''
                }
              </div>
            </div>

                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      
      <Sidebar role={props.role} loggedInStateChanger={props.loggedInStateChanger} />
      
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex bg-white shadow">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <Header setSidebarOpen={setSidebarOpen} role={props.role} user={props.user} company={props.company} />
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              {props.children}
            </div>
          </div>
        </main>
      </div>
    </div>
    );
  };
