import { Form, Formik } from "formik";
import { TextModalInput } from "../../components/Inputs/TextModalInput";
import * as Yup from 'yup';
import React, { useState } from "react";
import { ListInput } from "../../components/Inputs/ListInput";
import { apiClient } from "../../services/api";

export const AddBadge = (props) => {

    const [showForm, setShowForm] = useState(false);

    const [employees, setEmployees] = useState([]);
    const [selected, setSelected] = useState({ id: null, fullname: '-- Choisir collaborateur --'});
    
    function handleSelect(newVal) {
        setSelected(newVal);
    }

    function getEmployeesWithoutBadge() {
        apiClient.get('/api/badges/employees')
        .then(response => {
          setEmployees(response.data.employees);
        })

        .catch(error => console.error(error));
    }

    const handleSubmit = (values, actions) => {
       
        let badge = {
            badge_id: values.badge_id,
            user_id: values.user_id
        };

        setSelected({ id: null, fullname: '-- Choisir collaborateur --'});

        apiClient.post('/api/badges', {badge})
        .then(response => { 
            props.getBadges();
            actions.resetForm();
            hideForm();
        })
        .catch(error => console.log(error));

     }

    function addBadge() {
        setSelected({ id: null, fullname: '-- Choisir collaborateur --'});
        getEmployeesWithoutBadge();
        setShowForm(true);
    }

    function hideForm() {
        setShowForm(false);
    }

    return (
        <div>
            <Formik
                initialValues={{
                    badge_id: '',
                    user_id: '',
                }}
                validationSchema={Yup.object({
                    badge_id: Yup.string().required('Veullez saisir un badge ID'),
                    user_id: Yup.string(),
                })}
                onSubmit={handleSubmit}>
                {({ values }) => {
                    return (
                        <Form className="space-y-8 divide-y divide-gray-200">
                            <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                                <div className="flex items-center">
                                    <h1 className="text-xl font-bold flex-1 text-lg font-medium">Liste de badges</h1>
                                    <button type="button" onClick={addBadge} className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                                        <svg className="w-6 h-6 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                        </svg>
                                        Ajouter badge
                                    </button>
                                </div>
                            </div>
                            <ul className={"bg-white relative z-1 divide-y divide-gray-200 border-b border-gray-200 " + (showForm ? ' ': ' hidden') }>
                                <li>
                                    <div className="flex items-center px-4 py-4 sm:px-6 block bg-gray-50 h-28">
                                        <div className="min-w-0 flex-1 flex">
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
                                                <TextModalInput name="badge_id" label="Badge ID" />
                                                <div>
                                                    <ListInput name="user_id" employees={employees} selected={selected} handleSelect={handleSelect} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex items-center justify-center">
                                                <button type="submit">
                                                    <svg className="cursor-pointer w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                    </svg>
                                                </button>
                                                <svg onClick={hideForm} className="cursor-pointer w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}