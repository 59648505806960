import React, { useState } from "react";
import timeImg from '../../Icons/png/time.png';
import calendarImg from '../../Icons/png/calendar.png';
import { apiClient } from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';

export const EmployeesShow = () => {
    
    const location = useLocation();
    const history = useHistory();
    const [pointages, setPointages] = useState([]);

    React.useEffect(() => { 
      let mounted = true;
      if(mounted) {
        getPointages();   
      }
      return () => { mounted = false; }; }, []);
    
    function deletePointage(id) {
        let confirmed = window.confirm('Êtes-vous sûr de vouloir supprimer cette élément ?')
        if(confirmed) {
          
          apiClient.delete('/api/pointages/' + id)
          .then(res => {  
            getPointages();
          })
          .catch(err => console.log(err));
        }
      }
    
    function getPointages() {
        apiClient.get('/api/employee/' + location.state.id + '/pointages')
  
        .then(response => {
  
          setPointages(response.data.data)
  
        })
  
        .catch(error => console.error(error));
      }
  
      function returnToIndex() {
        history.push('/employees');
      }

      return(
        <div className="max-w-5xl mx-auto lg:min-w-0 lg:flex-1">
            <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                <div className="flex items-center">
                    <h1 className="text-xl font-bold flex-1 text-lg font-medium">
                        Pointages de {location.state.fullname}
                    </h1>
                    <button type="button" onClick={returnToIndex} className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                        <svg className="w-6 h-6 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                        Retour
                    </button>
                </div>
            </div>
        <ul className="bg-white relative z-0 divide-y divide-gray-200 border-b border-gray-200">
            {pointages.length === 0 ? 
            <li key="-1">
              <div className="flex items-center px-4 py-4 sm:px-6 block hover:bg-gray-50">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    {/* <img src={avatar} className="w-10 h-10" alt="Avatar Icon" /> */}
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      Aucun pointage
                    </div>
                  </div>
                </div>
              </div>
            </li> 
            : 
            pointages.map((pointage, key) => { return(   
                <li key={key}>
                  <div className="flex items-center px-4 py-4 sm:px-6 block hover:bg-gray-50">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex-shrink-0">
                        {/* <img src={avatar} className="w-10 h-10" alt="Avatar Icon" /> */}
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="mt-2 text-md font-medium truncate flex items-center">
                            <img src={timeImg} className="w-6 h-6 mr-3 mt-1" alt="Time icon" />
                              {pointage.heure}
                         </p>
                        </div>
                        <div className="hidden md:flex">
                          <div className="flex items-center">
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                <img src={calendarImg} className="w-6 h-6 mr-3 mt-1" alt="Time icon" />
                                {pointage.date}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    <div className="flex items-center">
                      <svg  onClick={() => { deletePointage(pointage.id); }} className="cursor-pointer w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                    </div>
                    </div>
                  </div>
              </li>
            )})}
        </ul>
    </div>
    );
}