import {  useField } from "formik";
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const RadioInput = ({ name, options, ...props }) => {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(name);
    const [selected, setSelected] = useState(props.initialValue);

    function handleSelect(newVal) {
        setSelected(newVal);
        helpers.setValue(newVal);
    }

    return (
        <RadioGroup value={selected} onChange={handleSelect}>
        <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
        <div className="bg-white rounded-md -space-y-px">
          {options.map((option, optionIdx) => (
            <RadioGroup.Option
              key={option.name}
              value={option.value}
              name="role"
              className={({ checked }) =>
                classNames(
                  optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  checked ? 'bg-cyan-50 border-cyan-200 z-10' : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked ? 'bg-cyan-600 border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-cyan-500' : '',
                      'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(checked ? 'text-cyan-900' : 'text-gray-900', 'block text-sm font-medium')}
                    >
                      {option.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(checked ? 'text-cyan-700' : 'text-gray-500', 'block text-sm')}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    );
  };