import { CreditCardIcon, UserGroupIcon, ViewListIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { StatsCard } from "../../components/Cards/StatsCard";
// import { ProjectStats } from "../../Projet/ProjectStats"
import { apiClient } from "../../services/api";

export const Dashboard = () => {

    const [role, setRole] = useState(false);
    const [stats, setStats] = useState({
        'tenants' : 0,
        'projects': 0,
        'pointages': 0,
        'badges': 0
    });

    function getStats() {

        apiClient.get('/api/stats')
            .then(response => setStats(response.data))
            .catch(error => console.log(error));
    }

    useEffect(() => {
        const role = localStorage.getItem('role');
        setRole(role);
        getStats();
    }, []);

    if (!role)
        return null;
    return (
        <div className="max-w-6xl mx-auto lg:min-w-0 lg:flex-1">
            <div className="px-4 pt-4 pb-4 xl:pt-6">
                <div className="flex items-center">
                    <h1 className="text-xl font-bold flex-1 text-lg font-medium">Tableu de bord</h1>
                </div>
            </div>
            {role === '1' ?
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">

                        <StatsCard category="Tenants" data={stats.tenants} icon={<svg className="h-6 w-6 text-gray-400" x-description="Heroicon name: outline/scale" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path>
                        </svg>} />
                    </div>
                </div>
                :
                ""
            }
            {role === '2' ?
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
                        {/* <StatsCard category="Projets" data={stats.projects} icon={<ClockIcon className="h-6 w-6 text-gray-400" />} /> */}
                        <StatsCard category="Collaborateurs" data={stats.employees} icon={<UserGroupIcon className="h-6 w-6 text-gray-400" />} />
                        <StatsCard category="Badges" data={stats.badges} icon={<CreditCardIcon className="h-6 w-6 text-gray-400" />} />
                        <StatsCard category="Pointages" data={stats.pointages} icon={<ViewListIcon className="h-6 w-6 text-gray-400" />} />
                    </div>
                </div>


                :
                ""
            }

            {role === '3' ?
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
                        {/* <StatsCard category="Projets" data={stats.projects} icon={<ClockIcon className="h-6 w-6 text-gray-400" />} /> */}
                        <StatsCard category="Pointages" data={stats.pointages} icon={<ViewListIcon className="h-6 w-6 text-gray-400" />} />
                    </div>
                </div>


                :
                ""
            }
        </div>
    );
}