import { Form, Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from 'yup';
import { TextInput } from '../../components/Inputs/TextInput';
import { RadioInput } from '../../components/Inputs/RadioInput';
import { apiClient } from '../../services/api';
import { useState } from "react";
import SimpleNotification from "../../components/Notifications/SimpleNotification";

export const EmployeesEdit = (props) => {
    
  const location = useLocation();
  const history = useHistory();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  
  const options = [
    { name: 'Administrateur', description: 'Administrateur', value: '2' },
    { name: 'Utilisateur', description: 'Utilisateur', value: '3' },
  ]

  function cancelEdit() {
    history.push('/employees');
  }
  
    const handleSubmit = (values) => {

      apiClient.put('/api/employees/' + values.id, {values}).then(response => {

        setNotificationMessage(response.data.message);
        setShowNotification(true);

        setTimeout(() => {  setShowNotification(false); history.push('/employees') }, 2000);
      
      }).catch(error => {
          setErrorMessage(error.response.data.message)
      })
    }
    return(
      <div>
    <Formik 
      initialValues={{
        id: location.state.id,
        lastname: location.state.lastname,
        firstname: location.state.firstname,
        email: location.state.email,
        role_id: location.state.role_id,
        password: '',
        password_confirmation: ''
      }}
      validationSchema={ Yup.object({
              firstname: Yup.string().required('Veullez saisir un prénom'),
              lastname: Yup.string().required('Veullez saisir un nom'),
              email: Yup.string().email().required('Veuillez saisir une adresse e-mail'),
              role_id: Yup.number().required('Veuillez choisir un rôle'),
              password: Yup.string().min(8, 'Votre mot de passe doit avoir au moins 8 caracteres').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
              password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
            })}
      onSubmit={handleSubmit}>
      { ({values}) => { return(
        <Form className="space-y-8 divide-y divide-gray-200">
        <div className="max-w-5xl mx-auto lg:min-w-0 lg:flex-1">

          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Informations collaborateur</h3>
            </div>
            <div className="bg-white px-3 space-y-6 sm:space-y-5">
              
              {/* <TextInput name="title" label="Title" /> */}
              
              <TextInput name="firstname" label="Prénom" />
              
              <TextInput name="lastname" label="Nom" />

              <TextInput name="email" label="E-mail" type="email" />
              
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Rôle
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                 <div className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
                    <RadioInput name="role_id" options={options} initialValue={values.role_id.toString()}/>
                 </div>
                </div>
              </div>
  
              <TextInput name="password" label="Mot de passe" type="password" />

              <TextInput name="password_confirmation" label="Confirmation mot de passe" type="password" />

            </div>
            <div className="pt-3 max-w-5xl mx-auto lg:min-w-0 lg:flex-1">
              <div className="flex justify-end items-center">
                <span className="rounded-md bg-red-300 px-3 mr-3">
                  {errorMessage}
                </span>
                <button
                  onClick={cancelEdit}
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  Sauvegarder
                </button>
              </div>
            </div>
          </div>
        </div>
         </Form>
        )}
      }
      </Formik>
      <SimpleNotification setShowNotification={setShowNotification} showNotification={showNotification} notificationMessage={notificationMessage} />
      </div>
    );
}