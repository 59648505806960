import { GuestLayout } from "../../Layouts/GuestLayout/GuestLayout";
import Time from 'react-time';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { apiClient } from "../../services/api";
import moment from 'moment';
import { LogOutIcon } from "../../Icons/LogOutIcon";

require('moment/locale/fr')

export const Timbreuse = (props) => {

  const logout = () => {
    apiClient.post('/logout').then(response => {

      if (response.status === 204) {
        props.loggedInStateChanger(false);
        localStorage.setItem('loggedIn', false);
        localStorage.removeItem('user');
        localStorage.removeItem('company');
        localStorage.removeItem('role');
      }
    })
  };

  const [value, setValue] = useState(new Date());
  const [ancienPointages, setAncienPointage] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(
      () => setValue(new Date()),
      1000
    );


    return () => {
      clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    apiClient.get('/api/ancienpointages')

    .then(response => {

      setAncienPointage(response.data.data)

    })

    .catch(error => console.error(error));
    
  }, []);

  function handlePointage() {

    let pointage = moment(value).format('DD-MM-YYYY HH:mm:ss');
    let type = 'entrant';

    apiClient.post('/api/pointages', {pointage, type})
    .then(response => {

      setAncienPointage(response.data.data)

    })
    .catch(error => console.log(error));
  }

  function changeLocation() {
    history.push('/pointages');
  }

  return (
    <GuestLayout>
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
      <div className="rounded-md bg-mp-time-green-500 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-white">
              Version 1.0
            </p>
            <p onClick={logout} className="mt-3 text-md md:mt-0 md:ml-auto">
              <span className="cursor-pointer whitespace-nowrap font-medium text-white flex ">
                <LogOutIcon className="mr-2 flex-shrink-0 h-6 w-6 text-white" />
                <span>
                 Se deconnecter 
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <section className="container mx-auto text-center py-3 mb-12">
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-center">
          <button 
            onClick={changeLocation}
            className="flex items-center px-3 py-3 border border-gray-300 bg-mp-time-green-500 shadow-sm text-2xl font-medium rounded-md text-white hover:bg-mp-time-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
            </svg>
            <span>
              Voir liste de pointages
            </span>
          </button>
        </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="max-w-md w-full p-4 py-5">
            <div className="relative bg-white shadow-xl rounded-lg overflow-hidden">
              <div className="relative z-10 p-5 w-full bg-white border-b-2 border-solid border-grey-500">
                <div className="flex justify-between items-center">
                  <h3 className="bg-transparent text-3xl appearance-none outline-none mr-2">
                    <Time value={value} format="LL" locale="fr" />
                  </h3>
                  {/* <h3 className="bg-transparent text-2xl appearance-none outline-none mr-2">juillet</h3>
                  <h3 className="bg-transparent text-2xl appearance-none outline-none">2021</h3> */}
                  <h3 className="bg-transparent text-3xl appearance-none outline-none">
                    <Time value={value} format="HH:mm:ss" locale="fr" />
                  </h3>
                </div>
              </div>
              <div className="transition-all ease-in duration-600 relative h-32 flex justify-center items-center p-6 border-t border-gray-300 text-gray-600">
                <button
                  onClick={handlePointage}
                  type="button"
                  className="w-full h-24 items-center px-6 py-3 border border-gray-300 bg-mp-time-green-500 shadow-sm text-2xl font-medium rounded-md text-white hover:bg-mp-time-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                  Pointage
                </button>
              </div>
            </div>
          </div>
          <div className="max-w-md w-full p-4 py-1">
            <div className="relative bg-white shadow-xl rounded-lg overflow-hidden">
            <div>
              <h3 className="text-lg my-2 leading-6 font-medium text-gray-900">
                Vos derniers pointages
              </h3>
              <div className="bg-white border border-gray-300 overflow-hidden rounded-md">
                <ul className="divide-y divide-gray-300">
                  {ancienPointages.map((pointage, key)=> {
                    return(
                      <li key={key} className="px-6 py-4">
                        {pointage.date} {pointage.heure}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</GuestLayout>
  );
}