import {
  Route,
  Redirect
} from "react-router-dom";

const ProtectedRoute = ({ component: Comp, loggedIn, tenant_id, path, ...rest }) => {
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          props.tenant_id = tenant_id;
          return loggedIn ? <Comp {...props} /> : <Redirect to="/login" />;
        }}
      />
    );
  };

  export default ProtectedRoute;