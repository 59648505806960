import { useFormik } from "formik";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import * as Yup from 'yup';
import { Logo } from "../../Icons/Logo";
import { GuestLayout } from "../../Layouts/GuestLayout/GuestLayout";
import { apiClient } from '../../services/api';


export const Login = (props) => {

  const [errorsMessage, setErrorsMessage] = useState([]);
  const [toHome, setToHome] = useState(false);
 
  const formik = useFormik({
    initialValues: {
        username: '',
        password: ''
    },
    validationSchema: Yup.object({
      username: Yup.string().email('Veuillez saisir une adresse e-mail valide').required('Veullez saisir une adresse e-mail'),
      password: Yup.string().required('Veuillez saisir un mot de passe'),
    }),
    onSubmit: values => {

      apiClient.get('/sanctum/csrf-cookie')
        .then(response => {

          apiClient.post('/login', {

                email: values.username,

                password: values.password

            }).then(response => {
                
                if(response.status === 200){
                   const user = response.data.fullname;
                   const company = response.data.company;
                   const role = response.data.role;
                   props.login(user, company, role);
                   setToHome(true);

                }
            }).catch(error => {
              setErrorsMessage(error.response.data.errors.email);
            })

        })
        .catch(err => console.log(err));

    },
});

  if(toHome === true) {
    return <Redirect to='/timbreuse' />
  }

  return (
    <GuestLayout>

      <section className="container mx-auto text-center py-3 mb-12">
        <div className="flex flex-col justify-center sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h1 className="w-full flex items-center justify-center my-2 text-5xl font-bold leading-tight text-center">
              <Logo className="mr-4 h-12 w-auto" color="#82c331" />
              MP Time
            </h1>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form onSubmit={formik.handleSubmit}>
              {errorsMessage.map((error, index) => {
                return (
                  <p key={index} className="text-sm text-red-600"><span>{error}</span></p> 
                );
              })}
                <h2 className="text-2xl font-bold">Se connecter</h2>
                <div className="text-left">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700">E-mail</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      onBlur={formik.handleBlur} 
                      onChange={formik.handleChange} 
                      value={formik.values.username} 
                      type="email"
                      name="username"
                      id="username"
                      className={"block w-full pr-10 focus:outline-none sm:text-sm rounded-md " + (formik.touched.username && formik.errors.username ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')}
                      aria-invalid="true"
                      aria-describedby="username-error"
                      />
                    <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.username && formik.errors.username ? ' visible' : ' invisible')}>
                      <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <p className="h-5 pl-1 -pt-1 text-sm text-red-600"> {formik.touched.username && formik.errors.username ? <span>{formik.errors.username}</span> : ' '}</p>
                </div>

                <div className="text-left">
                  <label htmlFor="password" className="block text-left text-sm font-medium text-gray-700">Mot de passe</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      onBlur={formik.handleBlur} 
                      onChange={formik.handleChange} 
                      value={formik.values.password} 
                      type="password"
                      name="password"
                      id="password"
                      className={"block w-full pr-10 focus:outline-none sm:text-sm rounded-md " + (formik.touched.password && formik.errors.password ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': '')}
                      aria-invalid="true"
                      aria-describedby="password-error"
                      />
                    <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.password && formik.errors.password ? ' visible' : ' invisible')}>
                      <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <p className="h-5 pl-1 -pt-1 text-sm text-red-600"> {formik.touched.password && formik.errors.password ? <span>{formik.errors.password}</span> : ' '}</p>
                </div>

                <div className="flex items-center justify-between">

                  <div className="text-sm my-4">
                    {/* <span className="font-medium text-indigo-600 hover:text-indigo-500">
                      Mot de passe oublier ?
                    </span> */}
                  </div>
                </div>

                <div>
                  <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-mp-time-green-500 hover:bg-mp-time-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-200">
                    Connexion
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
}