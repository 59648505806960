import { LoggedInUser } from "../../User/LoggedInUser";

export const Header = (props) => {

  return (
      <div className="px-4 sm:px-6 lg:max-w-6xl lg:px-8">
        <div className="py-6 flex items-center justify-between lg:border-t lg:border-gray-200">
          <div className="flex-1 min-w-0">
            <LoggedInUser 
              fullName={props.user} 
              company={props.company}
              role={props.role}
            />
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
           
          </div>
        </div>
      </div>
    );
  }