export const StatsCard = (props) => {
    return (

        <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        {props.icon}
                    </div>
                    <div className="ml-5 w-0 flex-1">
                        <dl>
                            <dt className="text-sm font-medium text-gray-500 truncate">
                                {props.category}
                            </dt>
                            <dd>
                                <div className="text-lg font-medium text-gray-900">
                                    {props.data}
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">

            </div>
        </div>
    );
}