import { Form, Formik } from "formik";
import { useState } from "react";
import { TextInput } from "../../components/Inputs/TextInput";
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { apiClient } from "../../services/api";
import { FormErrors } from "../../components/Notifications/FormErrors";

export const TenantsCreate = () => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState(null);
    const [errors, setErrors ] = useState([]);
   

    function handleSubmit(values) {
        apiClient.post('api/tenants', {tenant: values})
        .then(response => {
            history.push('/tenants');
        })
        .catch(error => {setErrorMessage(error.response.data.message);  setErrors(Object.values(error.response.data.errors))});
    }

    function cancelCreate() {
        history.push('/tenants');
    }

    return (
        <Formik
            initialValues={{
                enterprise: '', 
                title: '',
                lastname: '',
                firstname : '',
                email: '',
                password: '',
                password_confirmation: ''
            }}
            validationSchema={ Yup.object({
                enterprise: Yup.string().required('Veuillez saisir un nom de entreprise').min(3, 'Veuillez saisir au moins 3 caracteres'),
                title: Yup.string(),
                firstname: Yup.string().required('Veuillez saisir un prénom'),
                lastname: Yup.string().required('Veuillez saisir un nom'),
                email: Yup.string().email('Veuillez saisir une adresse e-mail valide.').required('Veuillez saisir une adresse e-mail'),
                password: Yup.string().min(8, 'Votre mot de passe doit avoir au moins 8 caracteres'),
                password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les deux mots de passe ne sont pas identiques!')
              })}
            onSubmit={handleSubmit}
        >

            {({ values }) => {
                return (
                    <Form className="space-y-8 divide-y divide-gray-200">
                        <div className="max-w-5xl mx-auto lg:min-w-0 lg:flex-1 pt-5">
                            {errors.length > 0 ? 
                            <FormErrors errors={errors} />
                            :
                            ''
                            }
                            <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Créer un nouveau tenant</h3>
                                </div>
                                <div className="bg-white px-3 space-y-6 sm:space-y-5">
                                    <TextInput name="enterprise" label="Entreprise" />
                                </div>
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Administrateur du tenant</h3>
                                </div>
                                <div className="bg-white px-3 space-y-6 sm:space-y-5">
                                    <TextInput name="title" label="Title" />

                                    <TextInput name="lastname" label="Nom" />

                                    <TextInput name="firstname" label="Prénom" />

                                    <TextInput name="email" label="E-mail" type="email" />

                                    <TextInput name="password" label="Mot de passe" type="password" />

                                    <TextInput name="password_confirmation" label="Confirmation mot de passe" type="password" />
                                </div>
                            </div>
                            <div className="pt-3 max-w-5xl mx-auto lg:min-w-0 lg:flex-1">
                                <div className="flex justify-end items-center">
                                    <span className="rounded-md bg-red-300 px-3 mr-3">
                                        {errorMessage}
                                    </span>
                                    <button
                                        type="button"
                                        onClick={cancelCreate}
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Sauvegarder
                                    </button>
                                </div>
                            </div>
                        </div>

                    </Form>
                );
            }}
        </Formik>
    );
}