import { LienNav } from '../Navigation/NavLink';
import { Logo } from '../../Icons/Logo';
import { HomeIcon } from '../../Icons/HomeIcon';
// import { ClockIcon } from '../../Icons/ClockIcon';
import { UsersIcon } from '../../Icons/UsersIcon';
import { CreditCardIcon } from '../../Icons/CreditCardIcon';
import { ListIcon } from '../../Icons/ListIcon';
import { PlusIcon } from '../../Icons/PlusIcon';
import { DownloadIcon } from '../../Icons/DownloadIcon';
import { apiClient } from '../../services/api';
import { LogOutIcon } from '../../Icons/LogOutIcon';
import { FolderIcon, UserGroupIcon } from '@heroicons/react/solid'
import React, { useRef, useState } from 'react';

export const Sidebar = (props) => {

  const [fileDownloadUrl, setFileDownloadUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const downloadinput = useRef(null);

  const logout = () => {
    apiClient.post('/logout').then(response => {

      if (response.status === 204) {
        props.loggedInStateChanger(false);
        localStorage.setItem('loggedIn', false);
        localStorage.removeItem('user');
        localStorage.removeItem('company');
        localStorage.removeItem('role');
      }
    })
  };

  function downloadFile() {
    apiClient.get('/api/downloadtransactions')
    .then(response => {
      let output = response.data.file;
      
      const blob = new Blob([output]);
      setFileDownloadUrl(URL.createObjectURL(blob));
     
      downloadinput.current.click();
      URL.revokeObjectURL(fileDownloadUrl);
    })
    .catch(error => setErrorMessage(error.response.data.message))
  }

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col flex-grow bg-cyan-700 pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <Logo className="h-8 mr-4" color="#fff" />
             <span className="text-3xl font-bold leading-tight text-center text-white">MP Time</span>
          </div>
          <nav className="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto" aria-label="Sidebar">
            <div className="px-2 space-y-1">

              <LienNav path='/' icon={<HomeIcon />} name="Accueil" />

              {props.role === '1' ?
                <LienNav path='/tenants' isActive={true} icon={<UserGroupIcon className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" />} name="Tenants" />
                :
                ''
              }

              {props.role === '2' ? 
                <div>

                  {/* <LienNav path='/projets' isActive={true} icon={<ClockIcon />} name="Projets" /> */}

                  <LienNav path='/employees' icon={<UsersIcon />} name="Collaborateurs" />

                  <LienNav path='/badges' icon={<CreditCardIcon />} name="Badges" />

                  <LienNav path='/pointages' icon={<ListIcon />} name="Mes pointages" />

                  <LienNav path='/transactionfiles' icon={<FolderIcon className="w-6 h-6 mr-4 text-cyan-200" />} name="Fichiers pointages" />

                  <LienNav path='/timbreuse' icon={<PlusIcon />} name="Timbreuse" />

                </div>
              :
              ""
              }

              {props.role === '3' ? 
                <div>

                {/* <LienNav path='/projets' isActive={true} icon={<ClockIcon />} name="Projets" /> */}

                <LienNav path='/pointages' icon={<ListIcon />} name="Mes pointages" />

                <LienNav path='/timbreuse' icon={<PlusIcon />} name="Timbreuse" />

              </div>
              : 
              ''
              }

              <button
                className={"w-full text-cyan-100 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}
                onClick={logout}>
                <LogOutIcon className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" />
                <span>Se deconnecter</span>
              </button>

            </div>
            <div className="mt-6 pt-6">
              <div className="px-2 space-y-1">
                {props.role === '2' ?
                <div>
                  <button onClick={downloadFile} type="button" className="w-full text-cyan-100 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md">
                    <DownloadIcon />
                    <span>
                      Produire fichier Transactions
                    </span>
                  </button>
                  {errorMessage === '' ? 
                  ''
                  :
                  <span className="flex justify-center items-center px-3 py-0.5 text-sm font-medium bg-red-100 text-red-800">
                    {errorMessage}
                  </span>
                  }
                  <a className="hidden"
                    download="TRANSACTIONS.TXT"
                    href={fileDownloadUrl}
                    ref={downloadinput}
                  >download it</a>
                </div>
                  :
                  ''
                }
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}