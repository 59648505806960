import axios from 'axios';
// MP Gest  KhjDMl8ZfmbQRjCOzCBmyV3cXYZVIFknh2FJLPvs 
const URL = process.env.REACT_APP_STAGE === 'production'
  ? 'https://api.mptime.mathspartner.ch'
  : 'http://api.mptime.test';

export const apiClient = axios.create({
    baseURL: 'https://api.mptime.mathspartner.ch', 
    withCredentials: true,
    headers: {
    	Authorization: 'Bearer ' + localStorage.getItem('access_token')    
    }
});

apiClient.interceptors.response.use(undefined, function (error) {

    switch (error.response.status) {
        case 401: 
            localStorage.setItem('loggedIn', false);
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            localStorage.removeItem('role');
            window.location.reload();
            break; // Not logged in
        case 419: console.log('Session expired'); break;// Session expired
        case 503: // Down for maintenance
            // Bounce the user to the login screen with a redirect back
            console.log('bounce back')
            break;
         case 500:
             alert('Ooops, something went wrong! The team have been notified.');
             break;
         default:            
            // Allow individual requests to handle other errors            
            return Promise.reject(error);
    }
});

// export const mpgestClient = axios.create({
//     baseURL: 'http://mpgest.test',
//     headers: {
//     	Authorization: 'Bearer HCEmDquDaDbIMBuiOD5eGxjGEaqqN6EkCi7vEPkM'   
//     }
// });