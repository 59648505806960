import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { apiClient } from "../../services/api";
import { format } from "date-fns";

export const AddPointage = (props) => {

    const [showForm, setShowForm] = useState(false);
    const formik = useFormik({
        initialValues: {
            pointage: new Date(),
        },
        validationSchema: Yup.object({
            pointage: Yup.date().required('Veullez choisir date et heure'),
        }),
        onSubmit: values => {

            apiClient.post('/api/pointages', {
                pointage: format(new Date(values.pointage), "dd-MM-yyyy HH:mm:ss"),
                type: 'manual'
            }).then(response => {
                props.getPointages();
                resetForm();
            })
            .catch(error => console.log(error));
        },
    });

    function handleChange(e) {
        console.log(e);
        formik.setValues({pointage: e[0]});
    }

    function addPointage() {
        setShowForm(true);
    }

    function resetForm() {
        setShowForm(false);
        formik.setValues({pointage: new Date()});
    }
    
    return (
        <form onSubmit={formik.handleSubmit} className="space-y-8 divide-y divide-gray-200">
            <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                <div className="flex items-center">
                    <h1 className="text-xl font-bold flex-1 text-lg font-medium">Pointages</h1>
                    <button type="button" onClick={addPointage} className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                        <svg className="w-6 h-6 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                        </svg>
                        Ajouter pointage
                    </button>
                </div>
            </div>
            <ul className="bg-white relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                <li className={showForm ? '' : 'hidden'}>
                    <div className="flex items-center px-4 py-4 sm:px-6 block hover:bg-gray-50 h-28">
                        <div className="min-w-0 flex-1 flex">
                            <div className="flex-shrink-0">

                            </div>
                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
                                <div className="flex items-center">
                                    <div className="flex-1">
                                        <label htmlFor="pointage" className="block text-sm font-medium text-gray-700">Pointage</label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <Flatpickr
                                                className={"block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2 border-gray-300 border-2 rounded-md"}
                                                data-enable-time
                                                data-time_24hr
                                                data-minute-increment={1}
                                                value={formik.values.pointage}
                                                onChange={handleChange}
                                                />

                                            <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" + (formik.touched.pointage && formik.errors.pointage ? ' visible' : ' invisible')}>
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="h-8 pl-1 pt-1 text-sm text-red-600"> {formik.touched.pointage && formik.errors.pointage ? <span>{formik.errors.pointage}</span> : ' '}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex items-center justify-center mt-5">
                                        <button type="submit">
                                            <svg className="cursor-pointer w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                        </button>
                                        <svg onClick={resetForm} className="cursor-pointer w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </form>
    );
}