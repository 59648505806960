import { ErrorMessage, useField } from "formik";

export const TextModalInput = ({ name, label, ...props }) => {
    const [field, meta] = useField(name);
    return (
        <div>
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">{label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input
                    {...field} {...props}
                    className={"block p-2 w-full pr-10 focus:outline-none sm:text-sm rounded-md"+ (meta.touched && meta.errors ? ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '')}
                    aria-invalid="true"
                    aria-describedby="badge_id-error"
                />
                <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"  + (meta.touched && meta.errors ? ' visible' : ' invisible')}>
                    <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                </div>
            </div>
            <p className="h-4 pl-1 pt-1 text-sm text-red-600"> 
                <ErrorMessage name={field.name} component="span" />
            </p>
        </div>
    );
  };